import React, { useEffect, useState } from 'react'
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData'
import { useLocation, useParams } from 'react-router-dom'
import useEditFormData from '../../../../Components/Hooks/useEditFormData'
import { useFormik } from 'formik'
import * as yup from 'yup';
import { NuDate, NuInput, NuTextArea } from '../../../../Components/Utils/Input'
import FormikSelectTwo from '../../../../Components/Utils/Select'
import axiosInstance from '../../../../Services/axiosCommon'
import EditIcon from '../../../../Components/Icons/Edit'
import { TextMediumBase } from '../../../../Components/Text/MediumText'
import NuAmount from '../../../../Components/Utils/NuAmount'
import { CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons'
import DeleteIcon from '../../../../Components/Icons/Delete'
import CloseIcon from '../../../../Components/Icons/Close'
import { videotype } from '../../../../Components/Utils/CustomAttrachmentupload'
import Video from '../../../../Components/Icons/Video'
import Doc from '../../../../Components/Icons/Doc'
import Xls from '../../../../Components/Icons/Xls'
import Txt from '../../../../Components/Icons/Txt'
import Pdf from '../../../../Components/Icons/Pdf'
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData'
import Select from 'react-select';
import { useCurrency } from '../../../../Context/CurrencyContext'

function CQEdit() {
    const {value:DeliveryTerm} = useFetchMataData(`/dropdown/dropdown-details/`,'DeliveryTerms_Supplierqoutes')
    const {value:PaymentTerms} = useFetchMataData(`/dropdown/dropdown-details/`,'PaymentTerms_Supplierqoutes')
    const {value:Validity} = useFetchMataData(`/dropdown/dropdown-details/`,'Validity_Supplierquotes')
    const {value:status,getData:refstatus} = useFetchMataData(`/dropdown/dropdown-details/`,'Status_customerQuotes')
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const { value: leadTime, getData: refLeadTime } = useFetchMataData(
      `/dropdown/dropdown-details/`,
      "LeadTime_Supplierqoutes"
    );
    const { value: Availablity, getData: refAvailablity } = useFetchMataData(`/dropdown/dropdown-details/`, 'Availablity_CustomerQuotes');

    const { quotesEditId } = useParams();
    const location = useLocation();
    const data = location?.state?.data;
    const { currencySymbol } = useCurrency();

    const [createNew, setCreateNew] = useState({
        date: "",
        deliverTerms: "",
        paymentTerms: "",
        paymentMethod: "",
        totalPrice: 0.00,
        prId: "",
        ref: "",
        availability: "",
        validity: "",
        specialNotes: "",
        leadTime: "",
        leadTime2: "",
        preparedBy: "",
        reviewedBy: "",
        approvedBy: "",
        remarks: "",
        comments: "",
        products: "",
        discount: "",
        approvalStatus:"",
        status:""
    
      })
      const [loading, setLoading] = useState(false);

      const [isEditing, setIsEditing] = useState(false);
      const [allData,setAllData] = useState([]);
      const [items, setItems] = useState([]);
      const [deletedItemDropDown,setDeletedItemDropDown] = useState([]);
      const [deletedItem,setDeletedItem] = useState([]);
      const [error,setError] = useState('')
      const [remove, setRemove] = useState([]);
      const [relatedDoc,setRelatedDoc] =useState([])
      const [removeFiles,setRemoveFiles] = useState([])

      const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
    
        }),
        onSubmit: async (value, { resetForm }) => {
          setLoading(true); 
          if (items.length === 0) {
            setError('Product table is empty');
            return;
          }
          const valueWithItems = {
            ...value,
            products: items,
            removedProducts:remove,
            removedFiles:removeFiles
          };
    
          editFormData(`/customer-quotations/edit-quotation/${value?.id}`, valueWithItems, resetForm, `/sales/quotes`);
          setLoading(false)
        }
      });

    

    const toggleEdit = () => {
        setIsEditing(!isEditing);
      };



      useEffect(()=>{
        getById(quotesEditId)
      },[data])

      const getCusByPr = (prId) => {
        if (prId !== "") {
          axiosInstance
            .get(`/PR/customer-name/${prId}`)
            .then(res => {
      
              formik.setFieldValue('customerName', res?.data?.customerName || '');
            })
            .catch(err => {
              console.log(err, "err");
            });
        }
      };

      useEffect(() => {
        if (formik.values.prId) {
        //   getPr(formik.values.prId);
          getCusByPr(formik.values.prId);
        }
      }, [formik.values.prId]);



      function UpdateAttachmentList(item) {
        let removeFile = relatedDoc.find((itemList) => itemList.file === item);
        let filterList = relatedDoc.filter((itemList) => itemList.file !== item);
        
        console.log("llll", filterList);
        setRemoveFiles([...removeFiles, removeFile.file]);
        setRelatedDoc(filterList);
      }

      function getById(quotesEditId){
        axiosInstance.get(`/customer-quotations/${quotesEditId}`).then(res=>{
            setCreateNew({
              id:res?.data?.data?.id||"",
              date: res?.data?.data?.date || "",
              deliverTerms: res?.data?.data?.deliveryTerms || "", 
              paymentTerms: res?.data?.data?.paymentTerms || "",
              paymentMethod: res?.data?.data?.paymentMethod || "",
              totalPrice: res?.data?.data?.totalPrice || "",
              prId: res?.data?.data?.prId || "",
              ref: res?.data?.data?.ref || "",
              availability: res?.data?.data?.availability || "",
              validity: res?.data?.data?.validity || "",
              specialNotes: res?.data?.data?.specialNotes || "",
              leadTime: res?.data?.data?.leadTime || "",
              preparedBy: res?.data?.data?.preparedBy || "",
              reviewedBy: res?.data?.data?.reviewedBy || "",
              approvedBy: res?.data?.data?.approvedBy || "",
              remarks: res?.data?.data?.remarks || "",
              comments: res?.data?.data?.comments || "",
              status: res?.data?.data?.status || "",
              approvalStatus: res?.data?.data?.approvalStatus || "",
              discount: res?.data?.data?.discount || "",
              product_name:res?.data?.data?.quoteProducts?.product_name || "",
              quantity:res?.data?.data?.quoteProducts?.quantity || "",
              price:res?.data?.data?.quoteProducts?.price || "",
            })
           setItems(res?.data?.data?.quoteProducts)
            setRelatedDoc(res?.data?.data?.rfqAttachments)
        }).catch(err=>{
            console.log("error",err);
            
        })
      }

      const handleAddDelPro = () => {
        const selectedProductId = formik.values.productId;
      
        // Find the selected product's details from the deletedItemDropDown (or deletedItem array)
        const selectedProduct = deletedItem.find(
          (item) => item.productId === selectedProductId
        );
      
        if (selectedProduct) {
          // Add the selected product to prList
          // setData((prevState) => ({
          //   ...prevState,
          //   products: [...prevState.products, selectedProduct],
          // }));
          setItems((prevPrList) => [...prevPrList, selectedProduct])
      
          // Optionally, remove the selected product from the dropdown after adding it to prList
          setDeletedItem((prevDeletedItems) =>
            prevDeletedItems.filter((item) => item.productId !== selectedProductId)
          );
        }
      };

      const updateTotalPrice = async() => {
        console.log("ssss testing ");
        
        const totalPrice = items.reduce((total, item) => {
          return total + (parseFloat(item.price) * parseFloat(item.quantity || 0));
        }, 0);
        console.log("total price", totalPrice);
        
        formik.setFieldValue('totalPrice', totalPrice);
      };
    
      const handlePriceChange = (e, index) => {
        const updatedItems = [...items];
        if (updatedItems[index]) {
          updatedItems[index].price = e.target.value;
          setItems(updatedItems);
          updateTotalPrice();
        }
      };
    
      const handleQuantityChange = (index, newQuantity) => {
        const updatedItems = [...items];
        if (updatedItems[index]) {
          updatedItems[index].quantity = newQuantity;
          setItems(updatedItems);
          updateTotalPrice();
        }
      };
    
      const handleIncrement = (index) => {
        handleQuantityChange(index, items[index].quantity + 1);
      };
    
      const handleDecrement = (index) => {
        handleQuantityChange(index, Math.max(1, items[index].quantity - 1));
      };
    
      const handleInputChange = (event, index) => {
        const value = Math.max(Number(event.target.value));
        handleQuantityChange(index, value);
      };


      const handleDeleteItem = (indexToDelete) => {
  
        const deletedItems = items.find(item => item.productId == indexToDelete);
        const updatedItems = items.filter(item => item.productId !== indexToDelete);
    
        
        if (deletedItems) {
          const priceToRemove = deletedItems.price || 0;
          const quantityToRemove = deletedItems.quantity || 0;
          const reduction = priceToRemove * quantityToRemove;    
          const newTotalPrice = updatedItems.length > 0 
          ? formik.values.totalPrice - reduction 
          : 0;
          formik.setFieldValue('totalPrice', newTotalPrice);
        setDeletedItem((prevDeletedItems) => [...prevDeletedItems,deletedItems]);
        setRemove((preRemove) => [...preRemove,deletedItems?.productId])
        }
        // const updatedItems = items.filter(item => item.productId !== indexToDelete);
        setItems(updatedItems);
        // updateTotalPrice();
      };

      const handleLeadTimeChange = (value, index) => {
        const updatedItems = [...items];
        updatedItems[index].leadTime = value.value;
        setItems(updatedItems);
      };

      const handleNoteChange = (e, index) => {
        const updatedItem = [...items];
        updatedItem[index].notes = e.target.value;
        setItems(updatedItem);
      };
  return (
    <div className="w-full h-full py-2 px-4">
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-260px)] bg-white rounded-md overflow-hidden ">
         <div className='w-full h-full py-4 px-3 flex justify-start content-start flex-wrap overflow-y-auto'>
         {formik.values.prId&&
            <NuInput
              disabled={true}
              type="text"
              label="Customer"
              placeholder="customer"
              formik={formik}
              name="customerName"
              width="w-full md:w-1/2 lg:w-1/3"
            />}
            <NuDate
              label="Quote Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
              <FormikSelectTwo
              type="text"
              label="Product Availability"
              placeholder="Choose"
              Options={Availablity}
              formik={formik}
              name="availability"
              width="w-full  md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
              type="text"
              label="Quotation Validity"
              placeholder="Choose"
              Options={Validity}
              formik={formik}
              name="validity"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <FormikSelectTwo
                type="text"
                label="Delivery Terms"
                placeholder="Choose"
                Options={DeliveryTerm}
                formik={formik}
                name="deliverTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />
             <FormikSelectTwo
                type="text"
                label="Payment Terms"
                placeholder="Choose"
                Options={PaymentTerms}
                formik={formik}
                name="paymentTerms"
                width="w-full md:w-1/2 lg:w-1/3"
              />
              
              <DropDownAddMetaData

                label="Status"
                placeholder="Choose"
                Options={status}
                formik={formik}
                name="status"
                width="w-full md:w-1/2 lg:w-1/3"
                getFunc={refstatus}
                displayProperty={"Status"}
                propertyName={"Status_customerQuotes"}
              />
            {/* <FormikSelectTwo
              type="text"
              label="Status"
              placeholder="Choose"
              Options={status}
              formik={formik}
              name="status"
              width="w-full  md:w-1/2 lg:w-1/3"
            /> */}
             <NuTextArea
              label="Special Notes"
              placeholder="Special Notes"
              formik={formik}
              name="specialNotes"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width="w-full md:w-1/2 lg:w-1/3"
              />
<p className=' text-xs font-medium 2xl:text-sm'>Supplier Quote Attachments</p>
<div className=' w-full px-4 flex flex-wrap gap-2 items-center'>

              {
relatedDoc.length>0 && relatedDoc.map((doc,index) => {
  let Ext = doc?.file_name.split(".").reverse()[0];
  return(
    <div
    className=" w-20 h-9 my-2 relative flex justify-center items-center rounded-lg"
    key={index}
    style={{
      border: "1px solid gray",
      borderColor:
        "rgb(148 163 184 / var(--tw-bg-opacity))",
    }}
  >
    <div
      className=" w-4 h-4 rounded bg-slate-400 absolute text-white cursor-pointer font-medium flex justify-center items-center  -top-2 -right-2 z-10"
      onClick={() => UpdateAttachmentList(doc?.file)}
    >
      <CloseIcon color="white" height="8" width="8" />
    </div>
    <div
      className=" w-20 h-9 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
      // onClick={() => handleImageClick(imgs)}
    >
      
      <div className=" w-8 h-8">
{videotype.includes(Ext) ? <Video /> : null}

{Ext === ("docx" || "doc"||"txt") ? (
<Doc />
) : Ext === ("xlsx" || "xls") ? (
<Xls />
) : Ext === "txt" ? (
<Txt />
) : Ext === "pdf" ? (
<Pdf />
) : Ext === "pptx" ? (
<>No file</>
) : 
<svg
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 512 512"
className="w-6 h-6 "
fill="#3B82F6"
>
<path d="M448 80c8.8 0 16 7.2 16 16l0 319.8-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3L48 96c0-8.8 7.2-16 16-16l384 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
</svg>
}
</div>
<p className=" w-[50px] text-[11px] truncate">{doc?.file_name}</p>
    </div>
  </div>
  )
})
}
              </div>
                   <div className="w-full h-0.5 opacity-30 bg-[#C4C2C2] my-6 mx-auto"></div>
                   {deletedItemDropDown.length > 0 ?
              <div className='w-full flex justify-start gap-2'>
                 <FormikSelectTwo
                   type="text"
                   label="Product"
                   placeholder="Product"
                   Options={deletedItemDropDown}
                   formik={formik}
                   name="productId"
                   width="w-full md:w-1/2 lg:w-[67%]"
                 />
                 <button
                onClick={handleAddDelPro}
                  type="button"
                 className=" p-1 mt-[44px] m-1 text-center h-8 cursor-pointer bg-[#048178] border border-solid border-white  text-xs font-medium  text-white  rounded-lg "
                   >
                Add
               </button>
              </div>
              :
              null
            }
            <div className='w-full bg-white overflow-y-scroll '>
               <div className='flex py-2 items-center'><p className='text-xs pr-1 text-red-500'>Note :</p><p className='text-[10px] text-slate-600'>CLick the edit icon near "Action" to edit Note, quantity, lead time and price.</p></div>
               <div className='w-full py-3  bg-[#048178] flex justify-between rounded-lg px-6'>
            {error && <p className="text-xs font-semibold text-red-500">{error}</p>}
              <p className="w-[5%]  text-left  text-white text-xs font-medium">S.N. </p>

                        <p className=" w-[30%] text-left text-white text-xs font-medium">
                        Product
              </p>
              <p className=" w-[5%] text-center text-white text-xs font-medium">
                Unit 
              </p>
              <p className=" w-[15%] text-center text-white text-xs font-medium">
                Qty
              </p>
              <p className="w-[10%] text-center text-white text-xs font-medium">
                  Lead Time
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Price
                </p>
                <p className="w-[15%] text-center text-white text-xs font-medium">
                  Total
                </p>
                <p className="w-[5%] text-center text-white text-xs font-medium">
                  Action
                </p>
                <button type="button" onClick={toggleEdit} className="text-center text-white text-xs font-medium">
                  <EditIcon color='white' width={"12"} height={"12"}/>
                </button>
            </div>
            {items?.length>0? <> 
                <div className="h-[calc(100vh-300px)] overflow-y-scroll">
                 {
                    items.map((List, index) => {
                       return (
                        <div key={index} className="w-full py-2 flex justify-between px-6 odd:bg-white even:bg-slate-100">
                         <p className="w-[5%]  text-left   text-xs font-light py-1">
                          {index + 1}
                         </p> 
                         <div className=' w-[30%] '>
                         <p className="text-left  text-xs font-light py-1">
                          {List?.product_name}
                         </p>
                         <p className="flex">
         <p className="text-[10px]  font-thin mt-1"> </p>
         {isEditing  ? (
 
  <input
                          type="text"
                          className="w-[65%] py-1 text-left px-1  border border-gray-100 text-[10px] font-thin "
                          name="notes"
                          placeholder="Enter here..."
                          value={List.notes || ""}
                          onChange={(e) => handleNoteChange(e, index)}
                        />
                      
  ):(
    <>
    {List?.notes && (
    <div className="relative group w-[40%]">
    <p className="text-left text-[#9e9fa7] text-[10px] font-thin truncate break-words">
      Notes : {List?.notes}
    </p>
    <div className="absolute hidden group-hover:block bg-gray-200 text-[#111537] text-[10px] font-light rounded-md px-2 py-1 w-max max-w-xs z-10">
      {List?.notes}
    </div>
  </div>  )}
  </>
)}
    </p>
    </div>
                         
                         <p className="w-[5%] text-center  text-xs font-light">
                          {List?.product_unit}
                         </p>
                         <div className="w-[15%] text-center  text-xs font-light flex items-center justify-center space-x-2">
                      {isEditing ? (
                        <>
                          <button
                            type="button"
                            className="border rounded-full p-1"
                            onClick={() => handleDecrement(index)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="w-16 text-center border  text-xs font-light"
                            name="quantity"
                            value={List.quantity}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          <button
                            type="button"
                            className="border rounded-full p-1"
                            onClick={() => handleIncrement(index)}
                          >
                            +
                          </button>
                        </>
                      ) : (
                        <p>{List.quantity}</p>
                      )}
                    </div>
                    <div className="w-[10%] text-center  text-xs font-light">
                     
                        <Select
                          value={{value:List.leadTime,label:List.leadTime}}
                          options={isEditing ? leadTime : []}
                          className="w-full"
                          isDisabled={!isEditing}
                          onChange={(value) => handleLeadTimeChange(value, index)}
                        />
                     
                    </div>
                    <div className="w-[15%] text-center  text-xs font-light">
                      {isEditing ? (
                        <input
                          type="number"
                          className="w-full py-1 text-center border  text-xs font-light"
                          name="price"
                          placeholder="Enter the price"
                          value={List.price || ''}
                          onChange={(e) => handlePriceChange(e, index)}
                        />
                      ) : (
                        <p>{List.price}</p>
                      )}
                    </div>
                     <p className=" w-[15%] text-center  text-xs font-light">
                      {(List.price * List.quantity ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                     </p>
                     <div className='w-[5%] text-center'>
                      <button
                        disabled={isEditing ? false : true}
                        onClick={() => handleDeleteItem(List?.productId)}
                        type="button"
                        className={`text-red-500 ${isEditing && "cursor-pointer"} ml-4 border-none`}
                      >
                        <DeleteIcon
                          color='red'
                          height={4}
                        />
                      </button>
                    </div>
                    </div>
                       ) 
                    })
                 }  
                </div>
             </>: <div className="w-full text-center text-[#93949f]">No Items here</div>}
            </div>
            <div className=" w-full my-6  flex justify-end items-center gap-4">
            <TextMediumBase content={"Total Price : "} />
              <p className="text-sm"> {currencySymbol} </p>
              <p className="w-64 h-8 border rounded-md text-xs pl-2 py-2">
                <NuAmount defaultCurrency={false} value={formik?.values?.totalPrice || 0} />
              </p>
            </div>
           <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
          <div className="md:flex justify-end gap-4">
            <CancelButton  handleClick={()=>{Oncancel(-1)}}/>
            <SubmitButton isLoading={loading}/>
          </div>
        </div> 
         </div>
        </div>
        </form>
    </div>
  )
}

export default CQEdit